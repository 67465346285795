export default defineNuxtRouteMiddleware(async (to, from) => {
  const { deviceId, problemId, category, manufacturerId } = to.params;

  const deviceData = await getDevices({
    page: 1,
    size: 1,
    uri_id: deviceId as string,
    manufacturer_uri_id: manufacturerId as string,
    type: category as CategoryType,
  });

  if (deviceData.count < 1) {
    return navigateTo('/not-found');
  }

  const device = deviceData.results[0];
  const problemDetails = await getProblemDetails(device.uriId, problemId as string);

  if (problemDetails.id == null || problemDetails.id === undefined) {
    navigateTo('/not-found');
  }

  const deviceState = useState("device", () => device);
  deviceState.value = device;
  const manufacturerState = useState("manufacturer", () => device.manufacturer);
  manufacturerState.value = device.manufacturer;
  const problemState = useState("problem", () => problemDetails);
  problemState.value = problemDetails;
});
